<template>
<div>
    <router-view></router-view>
</div>
</template>
<script>
//定义了一个空页面，并定义路由渲染位置，
//因为有默认跳转路由，默认跳转index.vue组件
    export default {
        data() {
            return {
            };
        },
        mounted() {

        },
        beforeDestroy() {

        },
        methods: {

        }
    };
</script>