<template>
<div ref="veContainer" class="container" :class="{ 'is-fluid' : isFluid, 'is-widescreen' : isWidescreen, 'is-fullhd' : isFullhd }">
    <slot>
    </slot>
</div>
</template>
<script>
//定义了一个空页面，并定义路由渲染位置，
//因为有默认跳转路由，默认跳转index.vue组件
    export default {
        data() {
            return {
            };
        },
        created() {
            
        },
        mounted() {
            let obj = this.$refs.veContainer
            if (window.addEventListener){
                // 非IE浏览器的事件
                obj.addEventListener('click', this.veClick , false)
                //obj.addEventListener('focus', this.veFocus , false)
                //obj.addEventListener('blur', this.veBlur , false)
                obj.addEventListener('mouseover', this.veFocus , false)
                obj.addEventListener('mouseout', this.veBlur , false)
                obj.addEventListener('dblclick', this.veDblclick , false)
            } else {
                // IE添加事件
                obj.attachEvent('onclick', this.veClick)
                //obj.attachEvent('onfocus', this.veFocus)
                //obj.attachEvent('onblur', this.veBlur)
                obj.attachEvent('onmouseover', this.veFocus)
                obj.attachEvent('onmouseout', this.veBlur)
                obj.attachEvent('ondblclick', this.veDblclick)
            }
        },
        beforeDestroy() {

        },
        props: {
            // 参数一是否流式布局，默认值false
            isFluid: {
                type: Boolean,
                default: false
            },
            // 参数二是否是全宽布局，默认值false
            isWidescreen: {
                type: Boolean,
                default: false
            },
            // 参数三是否是全宽布局，默认值false
            isFullhd: {
                type: Boolean,
                default: false
            }

        },
        methods: {
            veClick: function () {
                this.$emit('veclick')
            },
            veFocus: function () {
                this.$emit('vefocus')
            },
            veBlur: function () {
                this.$emit('veblur')
            },
            veDblclick: function () {
                this.$emit('vedblclick')
            }
        }
    };
// 将css样式暴露到全局，以供其它组件使用
</script>
<style lang="sass">
@import "../../../static/sass/bulma/bulma.sass";
</style>