<template>
<div class="content" :class="{ 'is-large': isLarge, 'is-medium': isMedium, 'is-small': isSmall }">
    <slot></slot>
</div>
</template>
<script>
    export default {
        data() {
            return {
            };
        },
        created() {
            
        },
        mounted() {
        },
        beforeDestroy() {

        },
        watch: {
        },
        props: {
            isLarge: {
                type: Boolean,
                default: false
            },
            isMedium: {
                type: Boolean,
                default: false
            },
            isSmall: {
                type: Boolean,
                default: false
            }
        },
        methods: {
        }
    };
</script>
<style scoped>
</style>
