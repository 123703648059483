<template>
<div class="modal" :class="{ 'is-active':isActive }">
  <div class="modal-background"></div>
  <div class="modal-content">
      <slot></slot>
  </div>
  <button v-if="isClose" @click="clickClose" class="modal-close is-large" aria-label="close"></button>
</div>
</template>
<script>
    export default {
        data() {
            return {
            };
        },
        created() {
            
        },
        mounted() {
        },
        beforeDestroy() {

        },
        watch: {
        },
        props: {
            isActive: {
                type: Boolean,
                default: false
            },
            isClose: {
                type: Boolean,
                default: false
            },
            isLarge: {
                type: Boolean,
                default: true
            }
        },
        methods: {
            clickClose () {
                this.$emit('veClose')
            }
        }
    };
</script>
<style scoped>
</style>
