import axios from 'axios';
import qs from 'qs';

let util = {

};
util.title = function (title) {
    title = title ? title + ' - 飞亚阳光运动馆' : '飞亚阳光运动馆';
    window.document.title = title;
};


const ajaxUrl = 'https://api.fyygydg.com/';

//util.ajax = axios.create({
//    baseURL: ajaxUrl,
//    timeout: 30000
//});
util.ajax = function (url, data, callback) {
    //data格式为{key:value, key:value}
    var result = axios({
        url: ajaxUrl + url,
        method: 'post',
        data: data,
        transformRequest: [
            function (data) {
                data = qs.stringify(data);
                //let ret = '';
                //for (let it in data) {
                //    ret += encodeURIComponent(it) + '=' + encodeURIComponent(data[it]) + '&'
                //}
                //console.log(ret);
                return data;
            }
        ],
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            //'appKey': resKey
        }
    }).then(function (response) {
        callback([true, response.data]);
    })
        .catch(function (error) {
            callback([false, error]);
        });
}

util.img2base64 = function (input_file, width = 300, height = 300, params = 'undefined', callback) {
    /*input_file：文件按钮对象或者图片文件base64编码
    width, height新生成的图片宽高
    var params = {
                    ut: ut,
                    step:step,
                    rect:[
                        10,10,200,200
                    ]
                };
    params可传入需要callback的对象，其中属性rect为是否需要在图片是画框
    callback会根据参数的个数返回对应数据*/
    if (typeof (FileReader) === 'undefined') {
        alert("抱歉，你的浏览器不支持 FileReader，不能将图片转换为Base64，请使用现代浏览器操作！");
    } else {
        try {
            if (typeof (input_file.files) == 'undefined') {
                var file = input_file;
                var img = new Image();
                img.src = file;
                //创建一个canvas对象
                var cvs = document.createElement('canvas');
                cvs.width = width; //canvas画布宽度
                cvs.height = height; //canvas画布高度
                //把大图片画到一个小画布
                var ctx = cvs.getContext("2d")
                ctx.drawImage(img, 0, 0, img.width, img.height, 0, 0, width, height);
                if (typeof (params.rect) !== 'undefined') {
                    var x = params.rect[0];
                    var y = params.rect[1];
                    var w = params.rect[2];
                    var h = params.rect[3];
                    //ctx.fillStyle = "#ffffff";//填充canvas的背景颜色
                    // ctx.fillRect(10, 10, 20, 20); //定义矩形框，参数分别表示 x轴,y轴,宽度,高度
                    ctx.lineWidth = 1; //边框宽度
                    ctx.strokeStyle = "#ff0000"; //边框颜色
                    ctx.strokeRect(x, y, w, h); //边框坐标及大小
                    //ctx.fillRect(50,50,100,100); //矩形边框及大小
                }
                //图片质量进行适当压缩
                var quality = width >= 1500 ? 0.5 :
                    width > 600 ? 0.6 : 1;
                //导出图片为base64
                var newImageData = cvs.toDataURL();
                //var resultImg = new Image();
                //resultImg.src = newImageData;
                if (params === 'undefined') {
                    callback(status = true, newImageData);
                } else {
                    callback(status = true, newImageData, params);
                }
            } else {
                var file = input_file.files[0];
                //这里我们判断下类型如果不是图片就返回 去掉就可以上传任意文件  
                if (!/image\/\w+/.test(file.type)) {
                    callback(status = false, '请确保文件为图像类型')
                }
                //图片转Base64核心代码
                var reader = new FileReader();
                reader.onload = function (e, mimeType) {
                    var img = document.createElement('img'); //创建一个虚拟图片对象
                    img.src = e.target.result;
                    //创建一个canvas对象
                    var cvs = document.createElement('canvas');
                    cvs.width = width; //canvas画布宽度
                    cvs.height = height; //canvas画布高度
                    //把大图片画到一个小画布
                    var ctx = cvs.getContext("2d")
                    ctx.drawImage(img, 0, 0, img.width, img.height, 0, 0, width, height);
                    if (typeof (params.rect) !== 'undefined') {
                        var x = params.rect[0];
                        var y = params.rect[1];
                        var w = params.rect[2];
                        var h = params.rect[3];
                        //ctx.fillStyle = "#ffffff";//填充canvas的背景颜色
                        // ctx.fillRect(10, 10, 20, 20); //定义矩形框，参数分别表示 x轴,y轴,宽度,高度
                        ctx.lineWidth = 1; //边框宽度
                        ctx.strokeStyle = "#ff0000"; //边框颜色
                        ctx.strokeRect(x, y, w, h); //边框坐标及大小
                        //ctx.fillRect(50,50,100,100); //矩形边框及大小
                    }
                    //图片质量进行适当压缩
                    var quality = width >= 1500 ? 0.5 :
                        width > 600 ? 0.6 : 1;
                    //导出图片为base64
                    var newImageData = cvs.toDataURL(mimeType, quality);
                    //var resultImg = new Image();
                    //resultImg.src = newImageData;
                    if (params === 'undefined') {
                        callback(status = true, newImageData);
                    } else {
                        callback(status = true, newImageData, params);
                    }
                }
                reader.readAsDataURL(file);
            }
        } catch (e) {
            callback(status = false, e)
        }
    }
}


export default util;