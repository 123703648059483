<template>
<div>
    <router-view></router-view>
    <ve-container v-on:veclick="conClick" v-on:vefocus="conFocus" v-on:veblur="conBlur" v-on:vedblclick="conDbl" :is-fullhd="true" style="width:100%;margin:0;max-width:none;">
        <ve-index>
            <div slot="trainContent" class="trainContentContainer">
                <div class="trainList">
                <div class="trainArea1">
                    <!-- <div class="trainAreaLeft"></div> -->
                    <div class="tranAreaRight">
                    <h2 class="heading-title">愿景：打造网络安全超级工程师！</h2>
                    <!-- <i class="circleIcon"></i> -->
                    <div class="trainListLabel">
                        <div class="trainListTitle">1、 背景介绍</div>
                        <p class="trainListContent">2017年6月1号，《中华人民共和国网络安全法》正式生效，网络安全上升到法律层面，对“保障网络安全、维护网络空间主权”的需求有了法律上的保证；
网络安全威胁日益加剧：2017年5月12号，勒索病毒全球爆发，波及99个国家7.5万台电脑。6月，勒索病毒变种再次肆虐全球；2017年4月，洲际酒店超过1000家旗下酒店遭遇支付卡信息泄露；2017年10月，雅虎30亿用户账号信息泄露2017年6月份，Verizon有600万用户的数据被泄露2017年，1.98亿美国选民信息泄漏2017年，5700万名Uber优步客户信息泄露</p>
                    </div>
                    <!-- <i class="circleIcon" style="background:#ee4749;"></i> -->
                    <div class="trainListLabel">
                        <div class="trainListTitle">2、 课程介绍</div>
                        <p class="trainListContent">具体参考宣传文档</p>
                    </div>
                    <!-- <i class="circleIcon" style="background:#21a9e1;"></i> -->
                    <div class="trainListLabel">
                        <div class="trainListTitle">3、 教学资源</div>
                        <p class="trainListContent">讲师团队：由具备网络安全行业8年以上从业经验的专业讲师和技术专家承担本次授课任务；实验设备：本次培训由赛博信安提供数百万的各类主流网络通信设备和网络安全设备，用于学员实验操作；教学材料：结合网络安全行业法律法规、一流厂商的认证培训、多年的网络安全实战和项目经验，我们开发了全套的教学教材，理论与实践相结合，既有理论的高度，又有实践的力度。</p>
                    </div>
                    <!-- <i class="circleIcon" style="background:#fad839;"></i> -->
                    <div class="trainListLabel">
                        <div class="trainListTitle">4、 培训特色</div>
                        <p class="trainListContent">完善的课程体系：从网络通信到网络安全，从理论到实践，从法律法规到网络安全设备，从安全测评到网络攻防，学员可完整学习和掌握网络安全相关知识；过硬的认证考试：赛博信安作为安全一流品牌厂商深信服的认证培训服务中心，可优先组织推荐学员参加深信服网络安全认证考试，获取资格证书，助力学员获取更好的工作机会；不仅仅是技术培训：网络安全并不靠网络安全设备堆砌得来，我们针对网络安全工程师所必备的知识、技能、素质做深度全方位打造，绝不仅仅是技术培训；全程实际演练：通过现场设备、云端服务器全程实际操作；参与设计项目，使学员完整参与网络安全设计、测评、分析、加固的全过程；完善的就业保障机制：以成都为主、四川为辅，涵盖西南五省/市，依托广泛的合作渠道，为学员提供就业平台，培训完成并通过考核后，100%推荐就业。（*详见培训协议条款）</p>
                    </div>
                    <!-- <i class="circleIcon" style="background:#ffffff;-webkit-transform: rotate(-90deg); -moz-transform: rotate(-90deg);postion:absolute"></i>
                    <i class="fa fa-plus" style="color: #607D8B;float: left;font-size: 1rem;z-index: 3;position: relative;left: -0.5rem;top: 0.8rem;"></i> -->
                    </div>
                </div>
                    <h2 class="heading-title" style="margin-top:5rem;">培训安排</h2>
                    <p class="trainListContent" style="margin-left:4rem;color:#566366;">1、培训时长：3.5个月； 
                           2、培训地点：多处培训地点，就近参加；
                           3、开班时间：7月初，具体时间提前15天通知；
                           4、招生人数：为保证教学质量，每班不超过30人。</p>
                    <!-- <i class="circleIcon"></i> -->
                    <div class="trainListLabel">
                        <div class="trainListTitle">5、 联系方式</div>
                        <p class="trainListContent">公司名称：成都赛博信安科技有限公司 公司地址： 总部：四川省成都市武侯区人民南路四段商鼎国际1-2-1909 分部/实训基地：四川省成都市崇州市飞云东路495号IPC枢纽大楼 联系方式：18010693609 邮箱：training@vector-china.cn</p>
                    </div>
                </div>
            </div>
        </ve-index>
    </ve-container>
</div>
</template>
<script>
import veContainer from "../components/layout/container/index.vue"
import veIndex from "../components/vector/index/index.vue"
    export default {
        data() {
            return {
                input1: '',
                input2: '',
                veList: [
                    { name: 'test',value: 'apple', selected: true },
                    { name: 'test',value: 'orange' },
                    { name: 'test',value: 'bananas' }
                ],
                veCheckboxList: [
                    { value: 'apple', disable: false },
                    { value: 'banana', disable: true },
                    { value: 'orange', disable: false },
                    { value: 'fruit', disable: false }
                ],
                veRadioList: [
                    { value: 'apple', disable: false },
                    { value: 'banana', disable: true },
                    { value: 'orange', disable: false },
                    { value: 'fruit', disable: false }
                ],
                veColumnList1: [
                    { name:'list1',class:'is-6'},
                    { name:'list2'},
                    { name:'list3'},
                    { name:'list4'},
                ],
                veColumnList2: [
                    { name:'list1',class:'is-8'},
                    { name:'list2'},
                ],
                veImage: 'http://gbres.dfcfw.com/Files/picture/20180121/B1B7AD2B309F8A7E1DCB53FB54D794D4.png',
                progress: 20,
                tags1: ['one','two','three']
            };
        },
        mounted() {

        },
        beforeDestroy() {

        },
        watch: {
            input1() {
                console.log(this.input1)
            },
            input2() {
                console.log(this.input2)
            }
        },
        methods: {
            conClick () {
                //console.log('点击容器')
            },
            conFocus () {
                //console.log('容器获得焦点')
            },
            conBlur () {
                //console.log('容器失去焦点')
            },
            conDbl () {
                //console.log('双击')
            },
            showChecklist (data) {
                console.log(data)
            },
            showradioresult (data) {
                console.log(data)
            },
            updateProgress () {
                this.progress = this.progress + 5
            }
        },
        components: {
            veContainer,
            veIndex
        }
    };
</script>
<style scope>
strong {
    color: #F5F5F5;
}
h2.heading-title {
    font-weight: 700;
    width: 30rem;
    margin-left: 4rem;
    margin-bottom: 2rem;
}

h2 {
    font-size: 2rem;
    font-weight: bold;
    text-transform: uppercase;
    color: #566366;
}

.comNews {
    width: 100%;
    height: 30rem;
    background: #8BC34A;
    padding: 5rem;
}


.trainAreaLeft {
    background: #1E2431;
    width: 0.3rem;
    height: 100%;
    margin-left: 1rem;
    float: left;
    margin-top: 6rem;
}


.circleIcon {  
    background: #8BC34A;
    color: #737881;
    display: block;
    width: 40px;
    height: 40px;
    -webkit-background-clip: padding-box;
    -moz-background-clip: padding;
    background-clip: padding-box;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
    text-align: center;
    -moz-box-shadow: 0 0 0 5px #f5f5f6;
    -webkit-box-shadow: 0 0 0 5px #f5f5f6;
    box-shadow: 0 0 0 5px #f5f5f6;
    line-height: 40px;
    font-size: 15px;
    float: left;
    z-index: 2;
    position: absolute;
}

.bd-notification {
  background-color: whitesmoke;
  border-radius: 3px;
  padding: 1.25rem 0;
  position: relative;
  text-align: center;
}
.bd-notification.is-info {
    background-color: #FF5722;
    color: #fff;
}
.bd-notification.is-purple {
    background-color: rgb(255, 34, 255);
    color: #fff;
}
.bd-notification.is-cyan {
    background-color: rgb(34, 240, 255);
    color: #fff;
}
.bd-notification.is-yellow {
    background-color: rgb(255, 214, 34);
    color: #fff;
}
.footer{
    background-color: #d415e6
}
</style>
