<template>
<div @click.stop="closeModal">
	<ve-banner v-if="bannerShow" :ban-width="screenWidth" :ban-height="screenHeight" :ban-list="banner">
		<div class="logo"><img src="images/logo.png" style="width:100%;height: 100%;"></div>
		<div class="title">赛博信安</div>
		<div class="banner-font">&nbsp;为客户提供<br/>
				全面的信息安全<br/>
				整体解决方案与专业服务</div>
		<div class="comInfo"><a href="#comInfo" style="text-decoration: none;color: white;">公司简介</a></div>
		<div class="banner-view"></div>
		<div class="banner-btn" style="display: none"></div>
		<div class="banner-number" style="display: none;"></div>
		<div class="banner-progres" style="display: none"></div>
		<div class="headerMenu" style="display:none;">
			<div class="menuList">
				<a href="#" class="menuLink">公司简介</a>
			</div>
			<div class="menuList">
				<a href="#" class="menuLink">公司动态</a>
			</div>
			<div class="menuList">
				<a href="#" class="menuLink">公司资质</a>
			</div>
			<div class="menuList">
				<a href="#" class="menuLink">公司服务</a>
			</div>
		</div>
	</ve-banner>

	<div class="comInfoArea">
		<div class="comInfoTitle"><a name="comInfo" style="text-decoration: none;color: #3d3d41;cursor: unset;">公司简介</a></div>
		<div class="comInfoLeft"><img src="./images/comInfo.webp" style="width:100%;height:100%;"></div>
		<div class="comInfoRight">
&nbsp;&nbsp;&nbsp;&nbsp;成都赛博信安科技有限公司专业为金融、政府、教育、医疗等行业客户提供信息安全、虚拟化、大数据解决方案和服务。
<br/>&nbsp;&nbsp;&nbsp;&nbsp;公司与信息安全及虚拟化领域的前沿厂商形成了紧密的合作关系，成为深信服、360等国内前沿厂商的核心合作伙伴。
<br/>&nbsp;&nbsp;&nbsp;&nbsp;公司是深信服在四川唯一的认证服务培训中心。
<br/>&nbsp;&nbsp;&nbsp;&nbsp;公司拥有一支经验丰富，以技术营销为导向的销售团队和专业的技术支持队伍，多人获得相关网络、信息安全、云计算厂商的技术资质认证。
		</div>
	</div>
	<div class="comNews">
		<div class="newsTitle">公司动态</div>
		<div class="newsContainer" slot="comNews">
			<div class="newsList">
				<img src="./images/news1.webp" style="width:100%;height:40%;">
				<div class="newsListTitle">四川科技职工大学与赛博信安签订校企合作协议</div>
				<div class="newsSummary" @click.stop="showContent(1)">
					2018年4月28日上午，四川科技职工大学与赛博信安签订校企合作协议签约仪式在四川科技职工大学会议室举行。…
				</div>
			</div>
			<div class="newsList">
				<img src="./images/news2.webp" style="width:100%;height:40%;">
				<div class="newsListTitle">公司获得的深信服四星级技术服务商授权</div>
				<div class="newsSummary" @click.stop="showContent(2)">
					凭借多年来丰富的信息安全项目实施经验，经过严格的考核及评审，公司喜获深信服四星级技术服务商授权！…
				</div>
			</div>
			<div class="newsList" style="margin-right:0;">
				<img src="./images/news3.webp" style="width:100%;height:40%;">
				<div class="newsListTitle">公司成功中标成都农商银行安全系统维保服务项目</div>
				<div class="newsSummary" @click.stop="showContent(3)">
					基于丰富的网络安全项目方案及实施经验，凭借完善的售后服务解决方案及服务能力，公司成功中标成都农商银行安全系统维保服务项目。…
				</div>
			</div>
		</div>
	</div>
	
	<div class="comNatural">
		<div class="comNaturalTitle">公司资质</div>
		<div class="comNaturalContainer">
			<div class="comNaturalList">
				<img src="./images/natural1.webp" style="width:100%;height:100%;">
				<div class="comNaturalListTitle">深信服渠道金牌合作伙伴</div>
			</div>
			<div class="comNaturalList">
				<img src="./images/natural2.webp" style="width:100%;height:100%;">
				<div class="comNaturalListTitle">深信服四星级合作伙伴</div>
			</div>
			<div class="comNaturalList">
				<img src="./images/natural3.webp" style="width:100%;height:100%;">
				<div class="comNaturalListTitle">深信服认证服务培训中心</div>
			</div>
			<div class="comNaturalList">
				<img src="./images/natural4.webp" style="width:100%;height:100%;">
				<div class="comNaturalListTitle">360铂金代理</div>
			</div>
		</div>
	</div>

	<div class="trainTitle">
		安全实训
	</div>

	<div class="trainContent">
		<div class="trainList">
			<img src="./images/practice1.webp" style="width:100%;height:40%">
			<div class="trainListTitle">背景介绍</div>
			<div class="trainListContent">2017年6月1号，《中华人民共和国网络安全法》正式生效，网络安全上升到法律层面，对“保障网络安全、维护网络空间主权”的需求有了法律上的保证；
网络安全威胁日益加剧：2017年5月12号，勒索病毒全球爆发，波及99个国家7.5万台电脑。6月，勒索病毒变种再次肆虐全球；2017年4月，洲际酒店超过1000家旗下酒店遭遇支付卡信息泄露；2017年10月，雅虎30亿用户账号信息泄露2017年6月份，Verizon有600万用户的数据被泄露2017年，1.98亿美国选民信息泄漏2017年，5700万名Uber优步客户信息泄露</div>
		</div>
		<div class="trainList">
			<img src="./images/practice2.webp" style="width:100%;height:40%">
			<div class="trainListTitle">教学资源</div>
			<div class="trainListContent">讲师团队：由具备网络安全行业8年以上从业经验的专业讲师和技术专家承担本次授课任务；实验设备：本次培训由赛博信安提供数百万的各类主流网络通信设备和网络安全设备，用于学员实验操作；教学材料：结合网络安全行业法律法规、一流厂商的认证培训、多年的网络安全实战和项目经验，我们开发了全套的教学教材，理论与实践相结合，既有理论的高度，又有实践的力度。</div>
		</div>
		<div class="trainList">
			<img src="./images/practice3.webp" style="width:100%;height:40%">
			<div class="trainListTitle">实训特色</div>
			<div class="trainListContent">完善的课程体系：从网络通信到网络安全，从理论到实践，从法律法规到网络安全设备，从安全测评到网络攻防，学员可完整学习和掌握网络安全相关知识；过硬的认证考试：赛博信安作为安全一流品牌厂商深信服的认证培训服务中心，可优先组织推荐学员参加深信服网络安全认证考试，获取资格证书，助力学员获取更好的工作机会；不仅仅是技术培训：网络安全并不靠网络安全设备堆砌得来，我们针对网络安全工程师所必备的知识、技能、素质做深度全方位打造，绝不仅仅是技术培训；全程实际演练：通过现场设备、云端服务器全程实际操作；参与设计项目，使学员完整参与网络安全设计、测评、分析、加固的全过程；完善的就业保障机制：以成都为主、四川为辅，涵盖西南五省/市，依托广泛的合作渠道，为学员提供就业平台，培训完成并通过考核后，100%推荐就业。（*详见培训协议条款）</div>
		</div>
		<div class="trainList">
			<img src="./images/practice4.webp" style="width:100%;height:40%">
			<div class="trainListTitle">实训安排</div>
			<div class="trainListContent">1、实训时长：3.5个月；<br/>2、实训地点：多处培训地点，就近参加；<br/>3、开班时间：7月初，具体时间提前15天通知；<br/>4、招生人数：为保证教学质量，每班不超过30人。</div>
		</div>
<!-- 		<slot name="trainContent">
		</slot> -->
	</div>

	<div class="footer">
		&copy; 2018&nbsp;成都赛博信安科技有限公司&nbsp;<a href="https://beian.miit.gov.cn/" target="_blank">蜀ICP备&nbsp;18014414号-1</a><div style="text-align:left;">地址：四川省成都市武侯区人民南路四段商鼎国际1-2-1909&nbsp;实训基地：四川省成都市崇州市飞云东路495号IPC枢纽大楼&nbsp;联系方式：18010693609 邮箱：training@vector-china.cn</div>
	</div>
	<ve-modal v-if="isModal1" :is-active="true">
		<ve-content style="margin-left: 20%;margin-right: 20%;" :is-large="true">modal1</ve-content>
	</ve-modal>
	<ve-modal v-if="isModal2" :is-active="true">
		<ve-content style="margin-left: 20%;margin-right: 20%;" :is-large="true">modal2</ve-content>
	</ve-modal>
	<ve-modal v-if="isModal3" :is-active="true">
		<ve-content style="margin-left: 20%;margin-right: 20%;" :is-large="true">modal3</ve-content>
	</ve-modal>

</div>
</template>
<script>
var banner=[];
import imageURL1 from "./images/banner1.webp";
banner[0] = imageURL1;
import imageURL2 from "./images/banner2.webp";
banner[1] = imageURL2;
import imageURL3 from "./images/banner3.webp";
banner[2] = imageURL3;
import imageURL4 from "./images/banner4.webp";
banner[3] = imageURL4;
import imageURL5 from "./images/banner5.webp";
banner[4] = imageURL5;
import imageURL6 from "./images/banner6.webp";
banner[5] = imageURL6;
import veContent from "../../element/content/index.vue"
import veModal from "../../component/modal/index.vue"
import veBanner from "./banner.vue"
//定义了一个空页面，并定义路由渲染位置，
//因为有默认跳转路由，默认跳转index.vue组件
    export default {
        data() {
            return {
				screenWidth: 0,
				screenHeight: 0,
				isModal1: false,
				isModal2: false,
				isModal3: false,
				modalContent: '<h1>haha</h1>',
				banner: banner,
				bannerShow: false
            };
        },
        mounted() {
			const that = this
			let ua = navigator.userAgent;
			let ipad = ua.match(/(iPad).*OS\s([\d_]+)/);
			let isIphone = !ipad && ua.match(/(iPhone\sOS)\s([\d_]+)/);
			let isAndroid = ua.match(/(Android)\s+([\d.]+)/);
			let isMobile = isIphone || isAndroid;
			var w;
			var h;
			if(isMobile) {
				isMobile = true
			} else {
				isMobile = false
			}
			if(isMobile) {
				//获取窗口宽度 
				if (window.innerWidth) 
				w = window.innerWidth; 
				else if ((document.body) && (document.body.clientWidth)) 
				w = document.body.clientWidth; 
				//获取窗口高度 
				if (window.innerHeight) 
				h = window.innerHeight/2; 
				else if ((document.body) && (document.body.clientHeight)) 
				h = document.body.clientHeight/2; 
			}else{
				//获取窗口宽度 
				if (window.innerWidth) 
				w = window.innerWidth; 
				else if ((document.body) && (document.body.clientWidth)) 
				w = document.body.clientWidth; 
				//获取窗口高度 
				if (window.innerHeight) 
				h = window.innerHeight; 
				else if ((document.body) && (document.body.clientHeight)) 
				h = document.body.clientHeight; 
			}
			that.screenWidth = w
			that.screenHeight = h
			that.bannerShow = true
			window.onresize = () => {
				return(() => {
					let ua = navigator.userAgent;
					let ipad = ua.match(/(iPad).*OS\s([\d_]+)/);
					let isIphone = !ipad && ua.match(/(iPhone\sOS)\s([\d_]+)/);
					let isAndroid = ua.match(/(Android)\s+([\d.]+)/);
					let isMobile = isIphone || isAndroid;
					var w;
					var h;
					if(isMobile) {
						isMobile = true
					} else {
						isMobile = false
					}
					if(isMobile) {
						//获取窗口宽度 
						if (window.innerWidth) 
						w = window.innerWidth; 
						else if ((document.body) && (document.body.clientWidth)) 
						w = document.body.clientWidth; 
						//获取窗口高度 
						if (window.innerHeight) 
						h = window.innerHeight/2; 
						else if ((document.body) && (document.body.clientHeight)) 
						h = document.body.clientHeight/2; 
					}else{
						//获取窗口宽度 
						if (window.innerWidth) 
						w = window.innerWidth; 
						else if ((document.body) && (document.body.clientWidth)) 
						w = document.body.clientWidth; 
						//获取窗口高度 
						if (window.innerHeight) 
						h = window.innerHeight; 
						else if ((document.body) && (document.body.clientHeight)) 
						h = document.body.clientHeight; 
					}
					that.screenWidth = w
					that.screenHeight = h
				})()
			}
        },
        beforeDestroy() {

		},
		watch: {
			screenWidth (val) {
				this.screenWidth = val
			},
			screenHeight (val) {
				this.screenHeight = val
			}
		},
        methods: {
			showContent(index) {
/* 				if (index === 1){
					this.isModal1 = true
				} else if (index === 2){
					this.isModal2 = true
				} else {
					this.isModal3 = true
				} */
			},
			closeModal(){
				this.isModal1 = false
				this.isModal2 = false
				this.isModal3 = false
			}
		},
		components: {
			veContent,
			veModal,
			veBanner
		}
    };
</script>
<style scope>
@import "./css/index.css"
</style>