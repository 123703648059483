import 'babel-polyfill';
import Vue from 'vue';
import VueRouter from 'vue-router';
import Vuex from 'vuex';
import VueI18n from 'vue-i18n';
import Util from './libs/util';
import App from './app.vue';
import Routers from './router.js';
//import 'vue-awesome/icons/flag'
//import 'vue-awesome/icons'
//import Icon from 'vue-awesome/components/Icon'
import rem from 'vue-rem'

//使用vue路由插件
//使用vuex存储插件
//使用vue翻译插件
Vue.use(VueRouter);
Vue.use(Vuex);
Vue.use(VueI18n);
Vue.use(rem, {
    design: 2000,
    size: 100,
    minWidth: 700
})

//Vue.component('icon', Icon)

// 路由配置
const RouterConfig = {
    mode: 'history',
    routes: Routers
};
const router = new VueRouter(RouterConfig);

/* router.beforeEach((to, from, next) => {
    iView.LoadingBar.start();
    Util.title(to.meta.title);
    next();
});

router.afterEach(() => {
    iView.LoadingBar.finish();
    window.scrollTo(0, 0);
}); */

const store = new Vuex.Store({
    state: {
        post: '',
        data: '',
    },
    getters: {

    },
    mutations: {

    },
    actions: {
        ajax(url, data) {
            const axios = Util;
            axios.ajax(url, data);
        }
    }
});

// 实例化vue对象，并渲染app.vue模板
new Vue({
    el: '#app',
    router: router,
    store: store,
    render: h => h(App)
});