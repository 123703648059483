import App from './app.vue';
import Index from './example/index.vue'
const routers = [{
    path: '/',
    redirect: '/Index', //定义路由重定向
    meta: {
        title: '主页'
    },
    component: App,
     children: [
        {
            path: '/Index',
            meta: {
                title: 'vue语法演示'
            },
            component: Index
        }
    ]
}];
export default routers;